@import "../../../variables";

@import "../PostPage.module.scss";

.title {
  padding-top: 100px;
  font-size: 6em;
  color: lighten($color: #31326f, $amount: 15%);
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: $default-breakpoint) {
    font-size: 4em;
  }
}

.imageContainer {
  position: relative;
  padding: 30px 0 50px;
  text-align: center;

  & img {
    position: relative;
    width: 100%;
  }

  & .dogImg {
    position: relative;
    width: 75%;
    margin-top: 3rem;

    @media (max-width: $default-breakpoint) {
      width: 100%;
    }
  }
}

.paragraph {
  text-align: justify;
}

.doggosnapAppStatus {
  color: #3498db;
  font-weight: bold;
}

.hidden {
  display: none;
}

.error {
  color: red;
}
