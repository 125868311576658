@import "../../../variables";

// inherit default section styles
@import "./SectionStyles";

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meImage {
  margin: 10% 0;
  width: 300px;
  border-radius: 50%;

  @media (max-width: $large-screen-breakpoint) {
    width: 250px;
    margin: 20% 0;
  }
}

.sectionSeparator {
  // extend sectionSeparator from imported styles
  @extend .sectionSeparator;
  width: 10%;
}

.cvButton {
  border: none;
  font-size: 16px;
  background-color: $secondary-color;
  border-radius: 50%;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: lighten($color: $secondary-color, $amount: 5%);
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: $default-breakpoint) {
    margin: auto;
  }
}

.iconContainer {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-wrap: normal;
}

.contactIcon {
  color: $secondary-color;
  font-size: 30px;
  padding: 0 0.5rem;
  margin-top: 1rem;

  &:hover {
    color: lighten($color: $secondary-color, $amount: 5%);
  }
}
