@import "../../../variables";

// inherit default section styles
@import "./SectionStyles";

.sectionHeader {
  @extend .sectionHeader;
  margin-bottom: 0;

  @media (max-width: $default-breakpoint) {
    margin-bottom: 2rem;
  }
}

.sectionFooter {
  @extend .sectionFooter;
  margin-bottom: 3rem;
}

@import "../../../variables";

.carouselContainer {
  transform: translateX(-4.5rem);
  height: 460px;

  @media (max-width: $large-screen-breakpoint) {
    transform: translateX(-5rem);
  }

  @media (max-width: $default-breakpoint) {
    transform: translateX(-0rem);
  }
}

.cardLink {
  display: block;
}

.cardContainer {
  width: 350px;

  @media (max-width: $default-breakpoint) {
    width: 90%;
  }
}

.cardImageGrad {
  display: block;
  position: relative;
}

.cardImageGrad::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 70%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 70%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 1) 100%
  );
}

.cardImage {
  display: block;
  height: 175px;
  object-fit: cover;
  overflow: hidden;

  @media (max-width: $large-screen-breakpoint) {
    height: 200px;
  }

  @media (max-width: $default-breakpoint) {
    height: 200px;
  }
}

.cardTitle {
  font-size: 1.5rem;

  @media (max-width: $default-breakpoint) {
    font-size: 1.2rem;
  }
}

.cardText {
  text-align: justify;

  @media (max-width: $default-breakpoint) {
    display: none;
  }
}

.cardPublishDate {
  font-style: italic;
  text-align: right;
}
