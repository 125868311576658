@import "../../variables";

.navbar {
  position: fixed;
  z-index: 5;

  justify-content: center;
  color: $light-text-color;
  background-color: #f8f8ff;
  box-shadow: 0 1px 2px 2px rgba($color: #000000, $alpha: 0.3);
  transition: transform 0.75s ease, background-color 1s ease;
}

.navbarHide {
  transform: translateY(-55px);
  background-color: $secondary-color;

  @media (max-width: $default-breakpoint) {
    transform: translateY(-150px);
  }
}

.navbarHideFully {
  transform: translateY(-100px);

  @media (max-width: $default-breakpoint) {
    transform: translateY(-250px);
  }
}

.text {
  color: darken($color: $primary-color, $amount: 25%);
}

.navUl {
  text-align: center;
}

.navUlLi {
  display: inline;
  float: none;
}

.navUlLiA {
  display: inline-block;
}
