@import "../../../variables";

// inherit card styles from the ProjectSectionCard
@import "./ProjectSectionCard.module.scss";

.modalContainer {
  max-width: 700px;
}

.skillIcon {
  height: 100px;
}

.modalTitle {
  padding: 2rem 0;
}
