// import Google font by name
@mixin google-font($font) {
  $font: unquote($font);
  @import url(https://fonts.googleapis.com/css?family=#{$font});
}

// sizes
$font-size: 16px;
$default-breakpoint: 1028px;
$max-column-width: 45em;

// color variables
$primary-color: #eeeeee;
$secondary-color: #00adb5;
$text-color: #2c3e50;
$light-text-color: #5e6e7d;

// breakpoints
$default-breakpoint: 1024px;
$large-screen-breakpoint: 1400px;
