@import "../../../variables";

.tagContainer {
  margin-top: 2rem;
  text-align: center;
  color: $light-text-color;
}

.tagLineBreak {
  display: none;
  @media (max-width: $default-breakpoint) {
    display: inline;
  }
}

.tagButton {
  color: $secondary-color;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 2rem;
  font-style: italic;

  @media (max-width: $default-breakpoint) {
    margin: 0;
  }

  &:focus {
    outline: none;
    background-color: transparent;
  }

  &:hover {
    color: darken($color: $secondary-color, $amount: 5%);
  }

  &:active {
    transform: scale(0.98);
  }

  &:first-of-type {
    margin-left: 4rem;
  }
}

.tagActive {
  color: darken($color: #005eb5, $amount: 5%);
  text-shadow: 0px 0.5px $secondary-color;
}

.postContainer {
  max-width: 800px;
}

.titleRow {
  margin-bottom: 0;
}

.postTitle {
  color: $text-color;
}

.postSubtitle {
  color: lighten($color: $text-color, $amount: 15%);
}

.publishDate {
  color: $light-text-color;
  font-style: italic;
  margin-bottom: 0;
  padding-bottom: 0;
}

.postTag {
  margin: 0 0.5rem;
  color: $secondary-color;
  font-weight: 500;
}

.imageContainer {
  height: 16rem;
  padding: 0;
}

.postImage {
  padding-top: 15px;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  overflow: hidden;

  @media (max-width: $default-breakpoint) {
    object-fit: contain;
  }
}

.textPreviewContainer {
  position: relative;
  height: 16.5rem;
  overflow: hidden;
  text-align: justify;
  padding: 0;

  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  &p {
    margin-top: 0;
    padding-top: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0.3) 70%,
      rgba(255, 255, 255, 0.8) 90%,
      rgba(255, 255, 255, 0.9) 95%,
      rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0.3) 70%,
      rgba(255, 255, 255, 0.8) 90%,
      rgba(255, 255, 255, 0.9) 95%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.textPreviewContainer h3 {
  font-size: 18px;
}

.bottomRow {
  color: $secondary-color;
  font-weight: 500;
  text-align: right;
  box-sizing: padding-box;

  &:hover {
    color: lighten($color: $secondary-color, $amount: 5%);
  }
}

.bottomLine {
  width: 100%;
  height: 1px;
  margin-top: 3rem;
  background-color: $secondary-color;
  border: none;
}
