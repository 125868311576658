@import "../../variables";

// inherit default section styles
@import "./home/SectionStyles";

.background {
  @extend .background;
  margin-top: 0;
  background-color: #393e46;
  color: $primary-color;
}

.sectionFooter {
  @extend .sectionFooter;
  color: $primary-color;
  max-width: 950px;
  margin: auto;
}

.contactIcon {
  color: $secondary-color;
  font-size: 30px;
  padding: 0 0.5rem;
  margin-top: 1rem;

  &:hover {
    color: lighten($color: $secondary-color, $amount: 5%);
  }
}
