@import "../../../variables";

@import "../PostPage.module.scss";

// import fonts
@include google-font("Architects+Daughter");

.title {
  padding-top: 100px;
  font-size: 4em;
  hyphens: none;

  color: $text-color;

  @media (max-width: $default-breakpoint) {
    font-size: 3.5em;
  }
}

.diarystaTitle {
  padding-top: 100px;
  font-size: 6em;
  font-family: "Architects Daughter", sans-serif;
  color: rgb(102, 187, 106);
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: $default-breakpoint) {
    font-size: 4em;
  }
}

.diarystaImg {
  position: relative;
  padding: 30px 0 50px;
  text-align: center;

  & img {
    position: relative;
    width: 50%;
    @media (max-width: $default-breakpoint) {
      width: 100%;
    }
  }
}
