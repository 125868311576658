@import "../../../variables";

// inherit default section styles
@import "./SectionStyles";

.modalParagraph {
  text-align: justify;
}

.fillerContainer {
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $large-screen-breakpoint) {
    height: 200px;
  }

  @media (max-width: $default-breakpoint) {
    display: none;
  }
}

.fillerIcon {
  color: $secondary-color;
  margin: auto;
  font-size: 50px;
  margin-top: 175px;

  @media (max-width: $default-breakpoint) {
    display: none;
  }
}
