@import "../../../variables";

@import "../PostPage.module.scss";

.title {
  padding-top: 100px;
  font-size: 4em;
  hyphens: none;

  color: $text-color;

  @media (max-width: $default-breakpoint) {
    font-size: 3.5em;
  }
}

.vecBrainTitle {
  font-style: italic;
  font-size: 4rem;
  background: linear-gradient(45deg, #00f, rgb(64, 0, 255), #00bfff, rgb(0, 191, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  padding-top: 75px;
  margin-bottom: 0px;
  font-weight: bold;
  font-family: 'Arial Black', sans-serif;


  @media (max-width: $default-breakpoint) {
    font-size: 4em;
  }
}

.vecBrainImg1 {
  position: relative;
  padding: 30px 0 50px;
  text-align: center;

  & img {
    position: relative;
    width: 75%;
    @media (max-width: $default-breakpoint) {
      width: 100%;
    }
  }
}

.vecBrainImg2 {
  position: relative;
  padding: 30px 0 50px;
  text-align: center;

  & img {
    position: relative;
    width: 100%;
  }
}
