// import variables
@import "variables";

// import fonts
@include google-font("Nunito+Sans");

// general CSS that should apply in all places
* {
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
  hyphens: auto;
}

a {
  color: $light-text-color;
}

a.highlight-link {
  color: lighten($color: $light-text-color, $amount: 15%);
}

.App {
  flex: 1;
  align-items: center;
  justify-content: center;
}

// add some CSS for the tables that needs to be globally available

.post-page-table {
  overflow-x: auto;
}

.post-page-table table {
  width: 100%;
  text-align: center;
  margin: 2em 0;
}

.post-page-table th,
.post-page-table td,
.post-page-table tr,
.post-page-table thead {
  padding: 0 15px;
  text-align: center;
}

.post-page-table tr:nth-child(even) {
  background-color: rgb(231, 236, 238);
}

.post-page-table tr:hover {
  background-color: #f5f5f5;
}
